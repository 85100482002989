/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Form, Input, Button, notification, Modal, Table } from "antd";
import APIService from "../../service/APIService";
import moment from "moment";
import { priceFormat } from "../../utils";
const MD5 = require("crypto-js/md5");
export default function UserDetailPage(props) {
  const dateFormat = "DD-MM-YYYY HH:mm:ss";
  const [form] = Form.useForm();
  const [player, setPlayer] = useState([]);
  const [gold, setGold] = useState([]);
  const [paymentLog, setPaymentLog] = useState([]);
  const parameters = new URLSearchParams(window.location.search);
  const userid = props.userid ? props.userid : parameters.get("userid");
  const user = useSelector((state) => state.user.userState);
  const [open, setOpen] = useState(false);
  const [StatusActive, setStatusActive] = useState([]);

  const columnPaymentLog = [
    {
      title: "Hình thức",
      width: 100,
      dataIndex: "method",
      key: "method",
    },
    {
      title: "Tổng xu nạp",
      width: 150,
      dataIndex: "gold",
      key: "gold",
      render: (data) => priceFormat(data),
    },
  ];

  async function updatePlayerPayment() {
    try {
      const data = await APIService.updatePlayerPayment(userid);
      if (data) getPlayerDetail();
    } catch (error) {
      console.error(error);
    }
  }

  async function resetPass() {
    try {
      let text = "Bạn có muốn reset mật khẩu tài khoản này không ?";
      if (window.confirm(text) === true) {
        const userbo = user.username;
        const pass = MD5("123456").toString();
        const data = await APIService.resetPass(userid, pass, userbo);
        if (data) {
          notification["success"]({
            message: "Thông báo",
            description: "Cập nhật thành công",
          });
          getPlayerDetail();
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function getPaymentStatictic() {
    try {
      const data = await APIService.getUserPaymentStatistic(userid, "", "");
      if (data) {
        setPaymentLog(data);
        setOpen(true);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function getPlayerDetail() {
    try {
      const data = await APIService.getUserDetail(userid);
      if (data) {
        setPlayer(data);
        console.log(data);
        if (data.status_active === "active") {
          setStatusActive(
            <Button
              className="text-center"
              type="primary"
              onClick={() => {
                updateStatus("banned");
              }}
            >
              Khóa tài khoản
            </Button>
          );
        } else {
          setStatusActive(
            <Button
              className="text-center"
              type="primary"
              onClick={() => {
                updateStatus("active");
              }}
            >
              Mở khóa tài khoản
            </Button>
          );
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function updateMoney(values) {
    try {
      const data = await APIService.updateMoney(userid, values.gold);
      if (data) {
        notification["success"]({
          message: "Thông báo",
          description: "Cập nhật xu thành công",
        });
        getPlayerDetail();
        setGold(0);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function updateStatus(status) {
    try {
      let action = "Khóa";
      if (status === "active") action = "Mở khóa";
      let text = "Bạn có muốn " + action + " tài khoản này không ?";
      if (window.confirm(text) === true) {
        const data = await APIService.updateStatus(userid, status);
        if (data) {
          notification["success"]({
            message: "Thông báo",
            description: "Cập nhật thành công",
          });
          getPlayerDetail();
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function resetAccountName(type) {
    try {
      let text = "Bạn có muốn reset tên tài khoản " + type + " này không ?";
      if (window.confirm(text) === true) {
        let wdBankAccountName = "";
        let wdMoMoAccountName = "";
        if (type === "BANK") wdBankAccountName = "empty";
        else if (type === "MOMO") wdMoMoAccountName = "empty";
        const data = await APIService.updateWDAccountName(
          userid,
          wdBankAccountName,
          wdMoMoAccountName
        );
        if (data) {
          notification["success"]({
            message: "Thông báo",
            description: "Cập nhật thành công",
          });
          getPlayerDetail();
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  const onFinish = (values) => {
    updateMoney(values);
  };

  function isAdmin() {
    return user.username === process.env.REACT_APP_USERNAME_ADMIN;
  }

  useEffect(() => {
    getPlayerDetail();
  }, [userid]);

  function getDisplay() {
    if (isAdmin()) return "block";
    else return "none";
  }

  function getDisplayDetail() {
    if (isAdmin()) return "grid";
    else return "none";
  }

  return (
    <>
      <div className="p-6 border-y-[1px]" style={{ display: getDisplay() }}>
        <p className="text-md font-semibold">CỘNG XU CHO USER ID: {userid}</p>
        <br></br>
        <Form
          layout="vertical"
          form={form}
          className="flex w-full  form-search-filer flex-col "
          onFinish={onFinish}
        >
          <Form.Item
            label={<p className="font-bold">Xu</p>}
            name="gold"
            value={gold}
            rules={[{ required: true, message: "Chưa nhập số xu" }]}
          >
            <Input type="number" className="w-full flex-1" placeholder="" />
          </Form.Item>
          <Form.Item>
            <div className="flex flex-1 justify">
              <Button
                htmlType="submit"
                className="text-white font-bold bg-gradient-to-r from-[#5a4edd] to-[#8a80fc] hover:bg-gradient-to-r hover:from-[#5a4edd] hover:to-[#8a80fc] focus:bg-gradient-to-r focus:from-[#5a4edd] focus:to-[#8a80fc] px-6 mt-4"
              >
                Thêm xu
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
      <div className="p-6 border-y-[1px]">
        <p className="text-md font-semibold">THÔNG TIN USER ID: {userid}</p>
        <div className="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
          <div className="col-span-1">
            <p className="text-base font-medium text-sm">Tên đăng nhập</p>
          </div>
          <div className="col-span-5">
            <Input value={player.username} />
          </div>
        </div>

        <div className="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
          <div className="col-span-1">
            <p className="text-base font-medium text-sm">Name</p>
          </div>
          <div className="col-span-5">
            <Input value={player.name} />
          </div>
        </div>

        <div className="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
          <div className="col-span-1">
            <p className="text-base font-medium text-sm">Ảnh đại diện</p>
          </div>
          <div className="col-span-5">
            <Input value={player.avatar} />
          </div>
        </div>

        {/* <div className="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
          <div className="col-span-1">
            <p className="text-base font-medium text-sm">Pass</p>
          </div>
          <div className="col-span-5">
            <Input value={player.pass} />
          </div>
        </div> */}

        <div className="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
          <div className="col-span-1">
            <p className="text-base font-medium text-sm">Bot</p>
          </div>
          <div className="col-span-5">
            <Input value={player.bot} />
          </div>
        </div>

        <div className="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
          <div className="col-span-1">
            <p className="text-base font-medium text-sm">IP</p>
          </div>
          <div className="col-span-5">
            <Input value={player.IP} />
          </div>
        </div>

        <div className="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
          <div className="col-span-1">
            <p className="text-base font-medium text-sm">Platform</p>
          </div>
          <div className="col-span-5">
            <Input value={player.platform} />
          </div>
        </div>

        <div className="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
          <div className="col-span-1">
            <p className="text-base font-medium text-sm">Level</p>
          </div>
          <div className="col-span-5">
            <Input value={player.level} />
          </div>
        </div>

        <div className="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
          <div className="col-span-1">
            <p className="text-base font-medium text-sm">Xu</p>
          </div>
          <div className="col-span-5">
            <Input value={player.gold} />
          </div>
        </div>

        <div className="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
          <div className="col-span-1">
            <p className="text-base font-medium text-sm">Gold EXT</p>
          </div>
          <div className="col-span-5">
            <Input value={player.goldExt}/>
          </div>
        </div>

        {typeof player['ruby'] != "undefined" ?
          <div className="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
            <div className="col-span-1">
              <p className="text-base font-medium text-sm">Ruby</p>
            </div>
            <div className="col-span-5">
              <Input value={player['ruby']}/>
            </div>
          </div> : null}


        <div className="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
          <div className="col-span-1">
            <p className="text-base font-medium text-sm">isSun</p>
          </div>
          <div className="col-span-5">
            <Input value={player.isSun} />
          </div>
        </div>

        <div
          className="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8"
          style={{ display: getDisplayDetail() }}
        >
          <div className="col-span-1">
            <p className="text-base font-medium text-sm">Mật khẩu Sun</p>
          </div>
          <div className="col-span-5">
            <Input value={player.passSun} />
          </div>
        </div>

        <div className="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
          <div className="col-span-">
            <p className="text-base font-medium text-sm">Đăng nhập lần cuối</p>
          </div>
          <div className="col-span-5">
            <Input
              value={moment(player.lastedLogin * 1000).format(dateFormat)}
            />
          </div>
        </div>

        <div className="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
          <div className="col-span-">
            <p className="text-base font-medium text-sm">Trạng thái</p>
          </div>
          <div className="col-span-3">
            <Input value={player.status_active} />
          </div>
          <div className="col-span-2">{StatusActive}</div>
        </div>

        <div className="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
          <div className="col-span-">
            <p className="text-base font-medium text-sm">Tổng xu nạp</p>
          </div>
          <div className="col-span-3">
            <Input value={priceFormat(player.moneyDeposit)} />
          </div>
          <div className="col-span-2">
            <Button
              className="text-center"
              type="primary"
              onClick={() => {
                getPaymentStatictic();
              }}
            >
              Xem chi tiết
            </Button>
          </div>
        </div>

        <div className="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
          <div className="col-span-">
            <p className="text-base font-medium text-sm">Tổng xu đổi</p>
          </div>
          <div className="col-span-3">
            <Input value={priceFormat(player.moneyWithdraw)} />
          </div>
        </div>

        <div className="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
          <div className="col-span-">
            <p className="text-base font-medium text-sm">Reset mật khẩu</p>
          </div>
          <div className="col-span-3">
            <Button
              className="text-center"
              type="primary"
              onClick={() => {
                resetPass();
              }}
            >
              Reset
            </Button>
          </div>
        </div>

        <div className="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
          <div className="col-span-">
            <p className="text-base font-medium text-sm">Update Nạp/Đổi</p>
          </div>
          <div className="col-span-3">
            <Button
              className="text-center"
              type="primary"
              onClick={() => {
                updatePlayerPayment();
              }}
            >
              Update Nạp/Đổi
            </Button>
          </div>
        </div>

        <div className="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
          <div className="col-span-">
            <p className="text-base font-medium text-sm">Tên rút bank</p>
          </div>
          <div className="col-span-3">
            <Input value={player.wdBankAccountName} />
          </div>
          <div className="col-span-2">
            <Button
              className="text-center"
              type="primary"
              onClick={() => {
                resetAccountName("BANK");
              }}
            >
              Reset
            </Button>
          </div>
        </div>

        <div className="items-center m-8 flex-1.2 grid grid-flow-row-dense grid-cols-8 gap-8">
          <div className="col-span-">
            <p className="text-base font-medium text-sm">Tên rút momo</p>
          </div>
          <div className="col-span-3">
            <Input value={player.wdMoMoAccountName} />
          </div>
          <div className="col-span-2">
            <Button
              className="text-center"
              type="primary"
              onClick={() => {
                resetAccountName("MOMO");
              }}
            >
              Reset
            </Button>
          </div>
        </div>
      </div>

      <Modal
        title=""
        centered
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        width={920}
        footer={false}
      >
        <Table
          rowClassName={(_record, index) =>
            index === 4 ? "table-active-row" : ""
          }
          columns={columnPaymentLog}
          dataSource={paymentLog}
          pagination={false}
        />
      </Modal>
    </>
  );
}
