import React, {useState, useEffect, useRef} from "react";
import { Form, Table, Input, DatePicker, Tooltip } from "antd";
import FormSearchFilter from "../../components/FormSearchFilter";
import APIService from "../../service/APIService";
import { priceFormat } from "../../utils";
import moment from "moment";
import getColumnSearchProps from "../../components/TableColumnSearchProps";

const { RangePicker } = DatePicker;
const dateFormatPicker = "DD-MM-YYYY";


export default function SlotLogPage() {
  const [dataTable, setDataTable] = useState([]);
  const [filterDate, setFilterDate] = useState({
    timeFrom: "",
    timeTo: "",
  });

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [filterText, setFilterText] = useState('');
  const [filterColumn, setFilterColumn] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const searchInput = useRef(null);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width:100,
      align: "center",
    },
    {
      title: "PHIÊN",
      dataIndex: "round_id",
      key: "round_id",
      align: "center",
      width:140,
    },
    {
      title: "GAME",
      dataIndex: "game",
      key: "game",
      width:160,

    },
    {
      title: "USER",
      dataIndex: "player",
      key: "player",
      width:140,
      align: "center",
      ...getColumnSearchProps(dataTable.length, "player", setFilterText, setFilterColumn, setCurrentPage, searchInput, searchText, setSearchText, searchedColumn, setSearchedColumn)
    },
    {
      title: "TÊN HIỂN THỊ",
      dataIndex: "display_name",
      key: "display_name",

      width:200,
    },
    {
      title: "MỨC CƯỢC",
      dataIndex: "bet",
      key: "bet",
      render: (data) => priceFormat(data),
      width:140,
    },
    {
      title: "DÒNG",
      dataIndex: "line_select",
      key: "line_select",
      width:160,
      ellipsis: {
        showTitle: false,
      },
      render: data => (
        <Tooltip placement="topLeft" title={data}>
          {data}
        </Tooltip>
      ),

    },
    {
      title: "DÒNG THẮNG",
      dataIndex: "line_win",
      key: "line_win",
      width:160,
      ellipsis: {
        showTitle: false,
      },
      render: data => (
        <Tooltip placement="topLeft" title={data}>
          {data}
        </Tooltip>
      ),

    },

    {
      title: "TIỀN THẮNG",
      dataIndex: "money_win",
      width:140,
      render: (data) => priceFormat(data),
      key: "money_win",
    },
    {
      title: "KẾT QUẢ",
      dataIndex: "result",
      key: "result",
      width:160,
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      render: data => (
        <Tooltip placement="topLeft" title={data}>
          {data}
        </Tooltip>
      ),
    },
    {
      title: "FREE SPIN",
      dataIndex: "free_spin",
      key: "free_spin",
      width:100,
      align: "center",
    },
    {
      title: "LÚC",
      dataIndex: "timestamp",
      key: "timestamp",
      width:160,
      align: "center",
      render: (data, data1, index) => (
        <p>{moment(data * 1000).format('DD-MM-YYYY hh:mm:ss')}</p>
      ),
    },
  ];

  async function getSlotLogList(filter = {}, page = 1, size = 10) {
    try {
      let wherePlayerId = "";
      let filterWhere = [];
      if (filter["player"]) {
        wherePlayerId = ` player like '%${filter["player"]}%'`;
        filterWhere.push(wherePlayerId);
      }
      const data = await APIService.slotLogList(filterDate.timeFrom, filterDate.timeTo, filterWhere.join(" AND "), page, size);
      if (data) {
        setDataTable(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  function onChangePage(page, _pageSize) {
    if (_pageSize !== pageSize) {
      setPageSize(_pageSize)
      setCurrentPage(1);
    } else if (page !== currentPage) {
      setCurrentPage(page)
    }
  }

  useEffect(() => {
    getSlotLogList({
      [filterColumn]: filterText
    }, currentPage, pageSize).then();
  }, [filterDate, currentPage, pageSize, filterText]);

  return (
    <>
      <FormSearchFilter
        title="Fish Log Detail"
        initialValues={filterDate}
        onFormLayoutChange={(value) => {
          if (value.time) {
            setFilterDate({
              ...filterDate,
              timeFrom: moment(value.time[0]).format('YYYY-MM-DD'),
              timeTo: moment(value.time[1]).format('YYYY-MM-DD'),
            });
          } else {
            setFilterDate({timeFrom: "", timeTo: ""});
          }
        }}
      >
        <Form.Item label="Time" className="form-datepicker-filter" name="time">
          <RangePicker format={dateFormatPicker}/>
        </Form.Item>
      </FormSearchFilter>

      <Table columns={columns} rowKey={'id'} dataSource={dataTable} pagination={{
        position: ['top'], total: 1000, current: currentPage, onChange: onChangePage, defaultCurrent: 1
      }}/>
    </>
  );
}
