import React, {useState, useEffect, useRef} from "react";
import {Form, Table, Input, DatePicker, Tooltip} from "antd";
import FormSearchFilter from "../../components/FormSearchFilter";
import APIService from "../../service/APIService";
import moment from "moment";
import getColumnSearchProps from "../../components/TableColumnSearchProps";

const {RangePicker} = DatePicker;
const dateFormatPicker = "DD-MM-YYYY";


export default function GameLogPage() {
  const [dataTable, setDataTable] = useState([]);
  const [filterDate, setFilterDate] = useState({
    timeFrom: "", timeTo: "",
  });

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [filterText, setFilterText] = useState('');
  const [filterColumn, setFilterColumn] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const searchInput = useRef(null);

  const columns = [{
    title: "ID", width: 120, dataIndex: "id", key: "id", align: "center",
  }, {
    title: "USER",
    width: 140,
    dataIndex: "owner",
    key: "owner",
    align: "center", ...getColumnSearchProps(dataTable.length, "owner", setFilterText, setFilterColumn, setCurrentPage, searchInput, searchText, setSearchText, searchedColumn, setSearchedColumn)
  }, {
    title: "PHIÊN", width: 180, dataIndex: "roundId", key: "roundId", align: "center",
  }, {
    title: "TIỀN CƯỢC", width: 180, dataIndex: "betMoney", key: "betMoney", align: "center",
  }, {
    title: "GAME", dataIndex: "game", key: "game", width: 180, align: "center",
  }, {
    title: "NỘI DUNG", width: 250, dataIndex: "content", key: "content", ellipsis: {
      showTitle: false,
    }, render: data => (<Tooltip placement="topLeft" title={data}>
      {data}
    </Tooltip>), align: "center",
  },

    {
      title: "LÚC",
      width: 200,
      dataIndex: "startTimeInt",
      key: "startTimeInt",
      align: "center",
      render: (data, data1, index) => (<p>{moment(data * 1000).format('DD-MM-YYYY hh:mm:ss')}</p>),
    },];

  async function getGamgLogList(filter = {}, page = 1, size = 10) {
    try {
      let wherePlayerId = "";
      let filterWhere = [];
      if (filter["owner"]) {
        wherePlayerId = ` content like '%\"userid\":\"${filter["owner"]}\"%'`;
        filterWhere.push(wherePlayerId);
      }

      const data = await APIService.gamgLogList(filterDate.timeFrom, filterDate.timeTo, filterWhere.join(" AND "), page, size);
      if (data) {
        console.log(data);
        setDataTable(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  function onChangePage(page, _pageSize) {
    if (_pageSize !== pageSize) {
      setPageSize(_pageSize)
      setCurrentPage(1);
    } else if (page !== currentPage) {
      setCurrentPage(page)
    }
  }

  useEffect(() => {
    getGamgLogList({
      [filterColumn]: filterText
    }, currentPage, pageSize).then();
  }, [filterDate, currentPage, pageSize, filterText]);

  return (<>
    <FormSearchFilter
      title="Game Log"
      initialValues={filterDate}
      onFormLayoutChange={(value) => {
        if (value.time) {
          setFilterDate({
            ...filterDate,
            timeFrom: moment(value.time[0]).format('YYYY-MM-DD'),
            timeTo: moment(value.time[1]).format('YYYY-MM-DD'),
          });
        } else {
          setFilterDate({timeFrom: "", timeTo: ""});
        }
      }}
    >
      <Form.Item label="Time" className="form-datepicker-filter" name="time">
        <RangePicker format={dateFormatPicker}/>
      </Form.Item>
    </FormSearchFilter>

    <Table columns={columns} dataSource={dataTable} rowKey={'id'} pagination={{
      position: ['top'],
      total: 1000,
      current: currentPage,
      onChange: onChangePage,
      defaultCurrent: 1
    }}/>
  </>);
}
