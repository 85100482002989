import React, {useLayoutEffect, useState} from "react";
import {Input, Form, Button, notification} from "antd";
import {useDispatch} from "react-redux";
import {selectUser, setUser} from "../redux/features/userSlice";
import {useNavigate} from "react-router-dom";
import {login} from "../service/APIService";
import {store} from "../redux/store";

export default function LoginPage() {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [authToken, setAuthToken] = useState("");

  useLayoutEffect(() => {
    const {token, role} = selectUser(store.getState()) || {token: ""};
    if (token && role) {
      setAuthToken(token);
      setTimeout(() => {
        if (role === "ADMIN") {
          navigate("/thongke-general");
        } else if (role === "USER") {
          navigate("/user-info");
        } else {
          navigate("/user-info");
        }
      }, 300);
    }
  }, [])

  const onFinish = async (values) => {
    try {
      const data = await login(values.username, values.password);

      const {password, role, ...user} = data;

      if (!user || !user.token || !role) {
        notification["error"]({
          message: "Thông báo", description: "Thông tin đăng nhập không chính xác",
        });
        return;
      }

      dispatch(setUser({...user, role}));
      setTimeout(() => {
        if (role === "ADMIN") {
          navigate("/thongke-general");
        } else if (role === "USER") {
          navigate("/user-info");
        } else {
          navigate("/user-info");
        }
      }, 300);
    } catch (e) {
      console.error(e);
      notification["error"]({
        message: "Thông báo", description: "Thông tin đăng nhập không chính xác",
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (authToken ? <div></div> : <div
    className="bg-gradient-to-r from-[#5a4edd] to-[#8a80fc] flex justify-center items-center"
    style={{
      width: window.innerWidth, height: window.innerHeight,
    }}
  >
    <div className="w-[350px] bg-white p-8 rounded-md	">
      <div className="w-[105px] h-[75px] bg-logo bg-no-repeat bg-cover m-auto mb-6"></div>
      <Form
        requiredMark={false}
        name="basic"
        layout="vertical"
        labelCol={{
          span: 18,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Tên đăng nhập"
          className="font-bold"
          name="username"
          rules={[{
            required: true, message: "Vui lòng nhập tên đăng nhập!",
          },]}
        >
          <Input className="w-full"/>
        </Form.Item>
        <Form.Item
          className="font-bold"
          label="Mật khẩu"
          name="password"
          rules={[{
            required: true, message: "Vui lòng nhập mật khẩu!",
          },]}
        >
          <Input.Password/>
        </Form.Item>

        <Form.Item className="mt-10">
          <Button
            className="w-full text-white font-bold bg-gradient-to-r from-[#5a4edd] to-[#8a80fc] hover:bg-gradient-to-r hover:from-[#5a4edd] hover:to-[#8a80fc] focus:bg-gradient-to-r focus:from-[#5a4edd] focus:to-[#8a80fc]"
            htmlType="submit"
          >
            ĐĂNG NHẬP
          </Button>
        </Form.Item>
      </Form>
    </div>
  </div>);
}
