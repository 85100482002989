import {message, notification} from "antd";
import axios from "axios";
import {store} from "../redux/store";
import {setUser, logout, selectUser} from "../redux/features/userSlice";

const DOMAIN = process.env.REACT_APP_API_URL;

const fetchAuth = () => {
  const state = store.getState();
  const user = selectUser(state);
  return user?.token;
};

const httpClient = axios.create({
  baseURL: DOMAIN
});

export const requestPost = (func, params) => {
  return new Promise((resolve, reject) => {
    const {token} = selectUser(store.getState()) || {token: ""};
    let config = {};
    if (token) {
      config.headers = {
        "Authentication": token
      }
    }
    httpClient.post(func, params, config).then(data => {
      resolve(responseExecute(data));
    }).catch(e => {
      reject(responseErrHandler(e));
    });
  });
}

export const requestGet = (func, params) => {
  return new Promise((resolve, reject) => {
    const {token} = selectUser(store.getState());
    let config = {};
    if (token) {
      config.headers = {
        "Authorization": token
      }
    }
    if (params) {
      config.params = params;
    }
    httpClient.get(func, config).then((data) => {
      resolve(responseExecute(data));
    }).catch(e => {
      reject(responseErrHandler(e));
    });
  });
}

export const get = async (func, params = {}) => {
  let url = DOMAIN + func;
  const headers = {
    'content-type': 'application/x-www-form-urlencoded',
    accesstoken: fetchAuth(),
  };
  const response = await axios.get(url, {headers, params});
  return response.data;
};

export const post = async (
  func,
  params = {},
  body = {},
  headers = {
    "Content-Type": "application/json",
  }
) => {
  // message.loading({ content: "Loading...", key: "load", duration: 0 });
  headers.accesstoken = fetchAuth();

  let url = DOMAIN + func;
  const response = await axios.post(url, body, {
    headers: headers,
    params,
  });
  return response.data;
};

export const put = async (func, params = {}) => {
  // message.loading({ content: "Loading...", key: "load", duration: 0 });
  let url = DOMAIN + func;
  const response = await axios.put(url, params);
  return response.data;
};

export const responseExecute = async (response, isShowProgress = false) => {
  return response.data;
};

export const responseErrHandler = async (error) => {
  if (error.response && error.response.status === 401) {
    notification["error"]({
      message: "Thông báo",
      description: "Phiên đăng nhập đã hết hạn",
    });
    store.dispatch(logout());
    return error;
  }
}

export const execute = async (response, isShowProgress) => {
  try {
    if (response.status === 2) {
      if (response.data) {
        if (isShowProgress) {
          notification["success"]({
            message: "Success",
            description: response.msg,
          });
        }
        return response.data;
      }
      return response;
    } else if (response.code === -1) {
      store.dispatch(setUser(null));
    }
    notification["error"]({
      message: "Error! An error occurred. Please try again later",
      description: response.msg || response,
    });
    throw response;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
