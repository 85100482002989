import React, {useState, useEffect, useRef} from "react";
import {Form, Table, DatePicker, Tooltip} from "antd";
import FormSearchFilter from "../../components/FormSearchFilter";
import APIService from "../../service/APIService";
import {priceFormat} from "../../utils";
import moment from "moment";
import getColumnSearchProps from "../../components/TableColumnSearchProps";

const {RangePicker} = DatePicker;
const dateFormatPicker = "DD-MM-YYYY";


export default function GameBetLogPage() {
  const [dataTable, setDataTable] = useState([]);
  const [filterDate, setFilterDate] = useState({
    timeFrom: "",
    timeTo: "",
  });

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [filterText, setFilterText] = useState('');
  const [filterColumn, setFilterColumn] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const searchInput = useRef(null);

  const columns = [
    {
      title: "ID",
      width: 120,
      dataIndex: "id",
      key: "id",
      align: "center",
    },
    {
      title: "USER",
      width: 140,
      dataIndex: "player",
      key: "owner",
      align: "center",
      ...getColumnSearchProps(dataTable.length, "player", setFilterText, setFilterColumn, setCurrentPage, searchInput, searchText, setSearchText, searchedColumn, setSearchedColumn)
    },
    {
      title: "PHIÊN",
      width: 180,
      dataIndex: "roundId",
      key: "roundId",
      align: "center",
    },
    {
      title: "TIỀN",
      width: 220,
      dataIndex: "gold",
      key: "gold",
      align: "right",
      render: data => {
        return <span>{priceFormat(data)}</span>
      }
    },
    {
      title: "TIỀN CẬP NHẬT",
      width: 220,
      dataIndex: "updateGold",
      key: "updateGold",
      align: "right",
      render: data => {
        return <span>{priceFormat(data)}</span>
      }
    },
    {
      title: "GAME",
      dataIndex: "game",
      key: "game",
      width: 180,
      align: "center",
    },
    {
      title: "NỘI DUNG",
      width: 250,
      dataIndex: "detail",
      key: "detail",
      ellipsis: {
        showTitle: false,
      },
      render: data => (
        <Tooltip placement="topLeft" title={data}>
          {data}
        </Tooltip>
      ),
      align: "left",
    },
    {
      title: "KẾT QUẢ",
      width: 250,
      dataIndex: "result",
      key: "result",
      ellipsis: {
        showTitle: false,
      },
      render: data => (
        <Tooltip placement="topLeft" title={data}>
          {data}
        </Tooltip>
      ),
      align: "left",
    },
    {
      title: "LÚC",
      width: 200,
      dataIndex: "timeEnd",
      key: "timeEnd",
      align: "center",
      render: (data, data1, index) => (
        <p>{data}</p>
      ),
    },
  ];

  async function getGameBetLogList(filter = {}, page = 1, size = 10) {
    try {
      let wherePlayerId = "";
      let filterWhere = [];
      if (filter["player"]) {
        wherePlayerId = ` player like '%${filter["player"]}%'`;
        filterWhere.push(wherePlayerId);
      }

      const data = await APIService.gameBetLogList(
        filterDate.timeFrom,
        filterDate.timeTo,
        filterWhere.join(" AND "),
        page,
        size
      );
      if (data) {
        setDataTable(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  function onChangePage(page, _pageSize) {
    if (_pageSize !== pageSize) {
      setPageSize(_pageSize)
      setCurrentPage(1);
    } else if (page !== currentPage) {
      setCurrentPage(page)
    }
  }

  useEffect(() => {
    getGameBetLogList({
      [filterColumn]: filterText
    }, currentPage, pageSize).then();
  }, [filterDate, currentPage, pageSize, filterText]);

  return (
    <>
      <FormSearchFilter
        title="Game Log"
        initialValues={filterDate}
        onFormLayoutChange={(value) => {
          if (value.time) {
            setFilterDate({
              timeFrom: moment(value.time[0]).format('YYYY-MM-DD'),
              timeTo: moment(value.time[1]).format('YYYY-MM-DD'),
            });
          } else {
            setFilterDate({timeFrom: "", timeTo: ""});
          }
        }}
      >
        <Form.Item label="Time" className="form-datepicker-filter" name="time">
          <RangePicker format={dateFormatPicker}/>
        </Form.Item>
      </FormSearchFilter>

      <Table columns={columns} rowKey={'id'} dataSource={dataTable} pagination={{
        position: ['top'],
        total: 1000,
        current: currentPage,
        onChange: onChangePage,
        defaultCurrent: 1
      }}/>
    </>
  );
}
