import React, {useState, useEffect, useRef} from "react";
import {Form, Table, Input, Tooltip, Space, Button} from "antd";
import FormSearchFilter from "../../components/FormSearchFilter";
import moment from "moment";
import APIService from "../../service/APIService";
import { priceFormat, isCSKH } from "../../utils";
import { useSelector } from "react-redux";
import {SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";

export default function PaymentCardNapPage() {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [filterText, setFilterText] = useState('');
  const [filterColumn, setFilterColumn] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleFilter = async (selectedKeys, confirm, dataIndex) => {
    if (selectedKeys[0]) {
      setFilterText(selectedKeys[0]);
      setFilterColumn(dataIndex);
      setCurrentPage(1);
    }
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
    setFilterText('');
    setCurrentPage(1);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters, close}) => (<div
      style={{
        padding: 8,
      }}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <Input
        ref={searchInput}
        placeholder={`nhập ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        style={{
          marginBottom: 8, display: 'block',
        }}
      />
      <Space>
        <Button
          size="small"
          onClick={() => handleFilter(selectedKeys, confirm, dataIndex)}
        >
          Lọc tất cả
        </Button>
        <Button
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          size="small"
          disabled={!!!dataTable.length}
        >
          Lọc trong trang
        </Button>
        <Button
          type={'link'}
          onClick={() => clearFilters && handleReset(clearFilters)}
          size="small"
          style={{
            width: 90,
          }}
        >
          Khôi phục
        </Button>
        <Button
          type="link"
          size="small"
          onClick={() => {
            close();
          }}
        >
          đóng
        </Button>
      </Space>
    </div>), filterIcon: (filtered) => (<SearchOutlined
      style={{
        color: filtered ? '#1677ff' : undefined,
      }}
    />), onFilter: (value, record) => {
      //console.log(value, record[dataIndex])
      return record[dataIndex] && record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
    }, onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    }, render: (text) => searchedColumn === dataIndex ? (<Highlighter
      highlightStyle={{
        backgroundColor: '#ffc069', padding: 0,
      }}
      searchWords={[searchText]}
      autoEscape
      textToHighlight={text ? text.toString() : ''}
    />) : (text),
  });

  const user = useSelector((state) => state.user.userState);
  const [dataTable, setDataTable] = useState([]);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 140,
      align: "center",
    },
    {
      title: "USER",
      dataIndex: "player",
      key: "player",
      width: 140,
      align: "center",
      ...getColumnSearchProps("player")
    }, {
      title: "USERNAME", dataIndex: "username", width: 200, key: "username", ...getColumnSearchProps("username")
    },
    {
      title: "TIỀN USER",
      dataIndex: "gold",
      key: "gold",
      width: 140,
      render: (data) => priceFormat(data),
      align: "center",
    },
    {
      title: "TIỀN NẠP",
      dataIndex: "updateGold",
      key: "updateGold",
      width: 140,
      render: (data) => priceFormat(data),
      align: "center",
    },
    {
      title: "MÃ THẺ",
      dataIndex: "cardCode",
      key: "cardCode",
      width: 200,
      // align: 'center',
      ...getColumnSearchProps("cardCode")
    },
    {
      title: "SERI",
      dataIndex: "cardSeri",
      key: "cardSeri",
      width: 200,
      ...getColumnSearchProps("cardSeri")
      // align: 'center',
    },
    {
      title: "MỆNH GIÁ",
      dataIndex: "menhGia",
      key: "menhGia",
      width: 140,
      render: (data) => priceFormat(data),
      align: "center",
    },
    {
      title: "NHÀ MẠNG",
      dataIndex: "nhaMang",
      key: "nhaMang",
      width: 140,
      align: "center",
      ...getColumnSearchProps("nhaMang")
    },
    {
      title: "CHI TIẾT",
      dataIndex: "detail",
      key: "detail",
      width: 300,
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      render: (data) => (
        <Tooltip placement="topLeft" title={data}>
          {data}
        </Tooltip>
      ),
    },
    {
      title: "TRẠNG THÁI",
      dataIndex: "status",
      key: "status",
      width: 140,
      align: "center",
      render: (data) => (data == 0 ? "Chờ" : "Thành công"),
    },
    {
      title: "NỀN TẢNG",
      dataIndex: "platform",
      key: "platform",
      width: 140,
      align: "center",
    },
    {
      title: "IP",
      dataIndex: "IP",
      key: "IP",
      width: 200,
      ellipsis: {
        showTitle: false,
      },
      render: (data) => (
        <Tooltip placement="topLeft" title={data}>
          {data}
        </Tooltip>
      ),
    },
    {
      title: "CẬP NHẬT LÚC",
      dataIndex: "updatedAt",
      key: "updatedAt",
      align: "center",
      width: 180,
      render: (data, data1, index) => (
        <p>{moment(data).format("DD-MM-YYYY HH:mm:ss")}</p>
      ),
    },
  ];

  async function getPaymentCardNapList(filterCol = {}, page = 1, size = 10) {
    try {
      let wherePlayerId = "";
      let filterWhere = [];
      if (filterCol["player"]) {
        wherePlayerId = ` player like '%${filterCol["player"]}%'`;
        filterWhere.push(wherePlayerId);
      }
      if (filterCol["nhaMang"]) {
        wherePlayerId = ` nhaMang like '%${filterCol["nhaMang"]}%'`;
        filterWhere.push(wherePlayerId);
      }
      if (filterCol["cardSeri"]) {
        wherePlayerId = ` cardSeri like '%${filterCol["cardSeri"]}%'`;
        filterWhere.push(wherePlayerId);
      }
      if (filterCol["cardCode"]) {
        wherePlayerId = ` cardCode like '%${filterCol["cardCode"]}%'`;
        filterWhere.push(wherePlayerId);
      }

      if (filterCol['username']) {
        const listUser = await APIService.searchUserInfo("", filterCol['username']);
        if (Array.isArray(listUser)) {
          const userids = listUser.map(i => i['userid']).join("','");

          filterWhere.push(` player IN('${userids}') `);
        } else {
          return setDataTable([]);
        }
      }

      const data = await APIService.paymentCardNapList(filterWhere.join(" AND "), page, size);
      if (data) {
        // cskh chỉ cho phép xem trong n ngày gần nhất
        if (isCSKH(user)) {
          let to = new Date();
          to.setHours(23);
          to.setMinutes(59);
          to.setSeconds(59);
          let from = new Date();
          from.setDate(
            to.getDate() - process.env.REACT_APP_LIMIT_CSKH_DATE_NUMBER
          );
          from.setHours(0);
          from.setMinutes(0);
          from.setSeconds(0);
          for (let i = 0; i < data.length; i++) {
            let createdDate = moment(data[i].timestamp);
            let limitDate = moment(from);
            if (createdDate.isBefore(limitDate)) {
              data.splice(i, 1);
              i--;
            }
          }
        }
        setDataTable(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  function onChangePage(page, _pageSize) {
    if (_pageSize !== pageSize) {
      setPageSize(_pageSize)
      setCurrentPage(1);
    } else if (page !== currentPage) {
      setCurrentPage(page)
    }
  }

  useEffect(() => {
    getPaymentCardNapList({
      [filterColumn]: filterText
    }, currentPage, pageSize).then();
  }, [currentPage, pageSize, filterText]);

  return (
    <>
      <Table columns={columns} rowKey={'id'} dataSource={dataTable} pagination={{
        position: ['top'],
        total: 1000,
        current: currentPage,
        onChange: onChangePage,
        defaultCurrent: 1
      }}/>
    </>
  );
}
