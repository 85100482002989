import React, {useState, useEffect, useRef} from "react";
import {Form, Table, Input, DatePicker, Tooltip} from "antd";
import FormSearchFilter from "../../components/FormSearchFilter";
import APIService from "../../service/APIService";
import {priceFormat} from "../../utils";
import moment from "moment";
import getColumnSearchProps from "../../components/TableColumnSearchProps";

const {RangePicker} = DatePicker;
const dateFormatPicker = "DD-MM-YYYY";


export default function FishLogPage() {
  const [dataTable, setDataTable] = useState([]);
  const [filterDate, setFilterDate] = useState({
    timeFrom: "", timeTo: "",
  });

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [filterText, setFilterText] = useState('');
  const [filterColumn, setFilterColumn] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const searchInput = useRef(null);

  const columns = [{
    title: "ID", dataIndex: "id", key: "id", width: 100, align: "center",
  }, {
    title: "PHÒNG", dataIndex: "room", key: "room", width: 100, align: "center",
  }, {
    title: "BÀN", dataIndex: "table", key: "table", width: 100, align: "center",
  }, {
    title: "USER",
    dataIndex: "player",
    key: "player",
    width: 160,
    align: "center", ...getColumnSearchProps(dataTable.length, "player", setFilterText, setFilterColumn, setCurrentPage, searchInput, searchText, setSearchText, searchedColumn, setSearchedColumn)
  }, {
    title: "USERNAME", width: 200, dataIndex: "username", key: "username",

  }, {
    title: "TIỀN KHI VÀO BÀN",
    dataIndex: "goldLogin",
    key: "goldLogin",
    width: 240,
    render: (data) => priceFormat(data), // align: "center",
  }, {
    title: "TIỀN KHI THOÁT BÀN",
    dataIndex: "goldLogout",
    key: "goldLogout",
    width: 240,
    render: (data) => priceFormat(data), // align: "center",
  },

    {
      title: "VÀO BÀN LÚC",
      dataIndex: "loginTime",
      key: "loginTime",
      align: "center",
      width: 140,
      render: (data, data1, index) => (<p>{moment(data).format('DD-MM-YYYY hh:mm:ss')}</p>),
    }, {
      title: "THOÁT BÀN LÚC",
      dataIndex: "logoutTime",
      key: "logoutTime",
      align: "center",
      width: 140,
      render: (data, data1, index) => (<p>{moment(data).format('DD-MM-YYYY hh:mm:ss')}</p>),
    },];

  async function getFishLogList(filter = {}, page = 1, size = 10) {
    try {
      let wherePlayerId = "";
      let filterWhere = [];
      if (filter["player"]) {
        wherePlayerId = ` player like '%${filter["player"]}%'`;
        filterWhere.push(wherePlayerId);
      }

      const data = await APIService.fishLogList(filterDate.timeFrom, filterDate.timeTo, filterWhere.join(" AND "), page, size);
      if (data) {
        setDataTable(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  function onChangePage(page, _pageSize) {
    if (_pageSize !== pageSize) {
      setPageSize(_pageSize)
      setCurrentPage(1);
    } else if (page !== currentPage) {
      setCurrentPage(page)
    }
  }

  useEffect(() => {
    getFishLogList({
      [filterColumn]: filterText
    }, currentPage, pageSize).then();
  }, [filterDate, currentPage, pageSize, filterText]);

  return (<>
    <FormSearchFilter
      title="Fish Log"
      initialValues={filterDate}
      onFormLayoutChange={(value) => {
        if (value.time) {
          setFilterDate({
            ...filterDate,
            timeFrom: moment(value.time[0]).format('YYYY-MM-DD'),
            timeTo: moment(value.time[1]).format('YYYY-MM-DD'),
          });
        } else {
          setFilterDate({timeFrom: "", timeTo: ""});
        }
      }}
    >
      <Form.Item label="Time" className="form-datepicker-filter" name="time">
        <RangePicker format={dateFormatPicker}/>
      </Form.Item>
    </FormSearchFilter>

    <Table columns={columns} dataSource={dataTable} rowKey={'id'} pagination={{
      position: ['top'], total: 1000, current: currentPage, onChange: onChangePage, defaultCurrent: 1
    }}/>
  </>);
}
