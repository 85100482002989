import React, {useState, useEffect} from "react";
import {
  Form,
  Button,
  Table,
  Input,
  DatePicker,
  Drawer,
  Tooltip,
  notification,
} from "antd";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import APIService from "../../service/APIService";
import moment from "moment";
import FormSearchFilter from "../../components/FormSearchFilter";
const { RangePicker } = DatePicker;
const dateFormatPicker = "YYYY-MM-DD hh:mm:ss";
const { TextArea } = Input;
export default function UserBroadcastLogPage() {
  const [dataTable, setDataTable] = useState([]);
  const [openCreate, setOpenCreate] = useState(false);
  const [form] = Form.useForm();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "kidey",
      width: "8%",
      align: "center",
    },
    {
      title: "NỘI DUNG",
      dataIndex: "content",
      key: "content",
      ellipsis: {
        showTitle: false,
      },
      render: (data) => (
        <Tooltip placement="topLeft" title={data}>
          {data}
        </Tooltip>
      ),
    },
    {
      title: "APPEAR",
      dataIndex: "appear",
      key: "appear",
      width: "8%",
      align: "center",
    },
    {
      title: "ƯU TIÊN",
      dataIndex: "priority",
      key: "priority",
      width: "10%",
      align: "center",
    },
    {
      title: "THỜI GIAN BẮT ĐẦU",
      dataIndex: "timeStart",
      key: "timeStart",
      align: "center",
      // width: "12%",
      render: (data, data1, index) => (
        <p>{moment(data).format("DD-MM-YYYY HH:mm:ss")}</p>
      ),
    },
    {
      title: "THỜI GIAN KẾT THÚC",
      dataIndex: "timeEnd",
      key: "timeEnd",
      align: "center",
      // width: "12%",
      render: (data, data1, index) => (
        <p>{moment(data).format("DD-MM-YYYY HH:mm:ss")}</p>
      ),
    },
    {
      title: "TẠO LÚC",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      width: 190,
      render: (data, data1, index) => (
        <p>{moment(data).format("DD-MM-YYYY HH:mm:ss")}</p>
      ),
    },
  ];

  async function getBroadcastLogList(page = 1, size = 10) {
    try {
      const data = await APIService.broadcastLogList(page, size);
      if (data) {
        setDataTable(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const onFinish = (values) => {
    getCreateBroadcast(
      values.content,
      values.numAppear,
      moment(values.time[0]).format("YYYY-MM-DD"),
      moment(values.time[1]).format("YYYY-MM-DD")
    ).then();
  };

  function onChangePage(page, _pageSize) {
    if (_pageSize !== pageSize) {
      setPageSize(_pageSize)
      setCurrentPage(1);
    } else if (page !== currentPage) {
      setCurrentPage(page)
    }
  }

  useEffect(() => {
    getBroadcastLogList(currentPage, pageSize).then();
  }, [currentPage, pageSize]);

  async function getCreateBroadcast(content, numAppear, timeFrom, timeTo) {
    try {
      const data = await APIService.createBroadcast(
        content,
        numAppear,
        timeFrom,
        timeTo
      );
      if (data) {
        notification["success"]({
          message: "Thành công",
          description: "Broadcast đã được tạo.",
        });
        setCurrentPage(1);
        getBroadcastLogList(currentPage, pageSize).then();
        form.resetFields();
        setOpenCreate(false);
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      <FormSearchFilter title="Broadcastlog Log">
        
        <div className="flex col-span-5  justify-end items-end ">
          <Button
            onClick={() => {
              setOpenCreate(true);
            }}
            className="text-white font-bold bg-gradient-to-r from-[#5a4edd] to-[#8a80fc] hover:bg-gradient-to-r hover:from-[#5a4edd] hover:to-[#8a80fc] focus:bg-gradient-to-r focus:from-[#5a4edd] focus:to-[#8a80fc] px-6"
          >
            TẠO BROADCAST
          </Button>
        </div>
      </FormSearchFilter>

      <Table columns={columns} rowKey={'id'} dataSource={dataTable} pagination={{
        position: ['top'], total: 1000, current: currentPage, onChange: onChangePage, defaultCurrent: 1
      }}/>
      <Drawer
        closable={false}
        placement="right"
        onClose={() => {
          setOpenCreate(false);
        }}
        open={openCreate}
        width={550}
        bodyStyle={{ padding: 0 }}
      >
        <div className="py-3 px-5 flex justify-between items-center bg-[#F8F8F8]">
          Thông tin tạo mới
          <Button
            type="link"
            onClick={() => {
              setOpenCreate(false);
            }}
          >
            <FontAwesomeIcon
              icon={faXmark}
              style={{ fontSize: "18px", color: "black" }}
            />
          </Button>
        </div>
        <div className="p-5">
          <Form
            layout="vertical"
            form={form}
            className="flex w-full  form-search-filer flex-col "
            onFinish={onFinish}
            
          >
            <Form.Item
              label={<p className="font-bold ">Content</p>}
              name="content"
            >
              <TextArea rows={4} className="w-full flex-1" />
            </Form.Item>

            <Form.Item
              label={<p className="font-bold ">Số lượng xuất hiện 1 phút</p>}
              name="numAppear"
            >
              <Input className="w-full flex-1" />
            </Form.Item>
            <Form.Item
              label={<p className="font-bold ">Thời gian xuất hiện</p>}
              className=" w-full"
              name="time"
            >
              <RangePicker showTime format={dateFormatPicker} />
            </Form.Item>
            <div className="flex flex-1 justify-end">
              <Button
                htmlType="submit"
                className="text-white font-bold bg-gradient-to-r from-[#5a4edd] to-[#8a80fc] hover:bg-gradient-to-r hover:from-[#5a4edd] hover:to-[#8a80fc] focus:bg-gradient-to-r focus:from-[#5a4edd] focus:to-[#8a80fc] px-6 mt-4"
              >
                TẠO BROADCAST
              </Button>
            </div>
          </Form>
        </div>
      </Drawer>
    </>
  );
}
