import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    userState: null
  },
  reducers: {
    setUser: (state, action) => {
      state.userState = action.payload
    },
    logout: (state, action) => {
      state.userState = {
        isLogout: true
      }
    }
  }
})

export const { setUser, logout } = userSlice.actions
export const selectUser = (state) => state.user.userState;
export default userSlice.reducer