/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from "react";
import {
  Form,
  Table,
  Input,
  Button,
  Drawer,
  Tabs,
  InputNumber,
  DatePicker,
  notification,
  Modal,
} from "antd";
import FormSearchFilter from "../../components/FormSearchFilter";
import moment from "moment";
import APIService from "../../service/APIService";
import {priceFormat} from "../../utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";

const dateFormatPicker = "DD-MM-YYYY";
const dateFormatApi = "YYYY-MM-DD";
export default function PaymentCodeListPage() {
  const [dataTable, setDataTable] = useState([]);
  const [filter, setFilter] = useState({});
  const [openCreate, setOpenCreate] = useState(false);
  const [openCreateTable, setOpenCreateTable] = useState(false);
  const [codeText, setCodeText] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [form] = Form.useForm();
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 200,
    },
    {
      title: "MIN",
      dataIndex: "minLevel",
      key: "minLevel",
      width: 100,
      align: "center",
    },
    {
      title: "BẮT ĐẦU LÚC",
      dataIndex: "startedAt",
      key: "startedAt",
      align: "center",
      width: 140,
      render: (data, data1, index) => (
        <p>{moment(data * 1000).format("DD-MM-YYYY")}</p>
      ),
    },
    {
      title: "THỜI HẠN",
      dataIndex: "expiredAt",
      key: "expiredAt",
      align: "center",
      width: 140,
      render: (data, data1, index) => (
        <p>{moment(data * 1000).format("DD-MM-YYYY")}</p>
      ),
    },
    {
      title: "NUMBER",
      dataIndex: "number",
      key: "number",
      align: "center",
      width: 140,
    },
    {
      title: "TIỀN ĐỔI",
      dataIndex: "goldGet",
      key: "goldGet",
      width: 140,
      render: (data) => priceFormat(data),
    },
    {
      title: "LOẠI",
      dataIndex: "type",
      width: 140,
      align: "center",
      key: "type",
    },
    {
      title: "TẠO LÚC",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      width: 140,
      render: (data, data1, index) => (
        <p>{moment(data * 1000).format("DD-MM-YYYY")}</p>
      ),
    },
  ];

  async function getPaymentCodeList() {
    try {
      const data = await APIService.paymentCodeList(
        filter.code,
        filter.type,
        filter.money,
        filter.number,
        filter.expired,
        (currentPage - 1) * pageSize,
        pageSize
      );
      if (data) {
        data.sort((a, b) => a.createdAt - b.createdAt).reverse();
        console.log(data);
        setDataTable(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const onFinish = (values) => {
    if (values.goldGet > 100000) {
      notification["error"]({
        message: "Lỗi",
        description: "Xu nhận không được vượt quá 100,000",
      });
      return;
    }
    getCreateGiftcode(
      values.code,
      values.number,
      values.goldGet,
      1,
      values.type,
      moment().format(dateFormatApi),
      values.expiredAt
    );
  };

  const onFinishCodeRandom = (values) => {
    if (values.goldGet > 100000) {
      notification["error"]({
        message: "Lỗi",
        description: "Xu nhận không được vượt quá 100,000",
      });
      return;
    }
    getCreateCodeRandom(
      values.prefix,
      values.suffix,
      values.length,
      values.number,
      values.goldGet,
      1,
      values.type,
      moment().format(dateFormatApi),
      values.expiredAt
    );
  };

  const disabledDate = (current) => {
    return current && current.valueOf() < Date.now();
  };

  async function getCreateGiftcode(
    code,
    number,
    goldGet,
    minLevel,
    type,
    startedAt,
    expiredAt
  ) {
    try {
      const data = await APIService.createGiftCode(
        code.trim(),
        number,
        goldGet,
        minLevel,
        type,
        startedAt,
        expiredAt
      );
      if (data) {
        notification["success"]({
          message: "Thành công",
          description: "GiftCode đã được tạo.",
        });
        getPaymentCodeList().then();
        form.resetFields();
        setOpenCreate(false);
        let text = data.id;
        setCodeText(text);
        setOpenCreateTable(true);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function getCreateCodeRandom(
    prefix,
    suffix,
    length,
    number,
    goldGet,
    minLevel,
    type,
    startedAt,
    expiredAt
  ) {
    try {
      const data = await APIService.createCodeRandom(
        prefix,
        suffix,
        length,
        number,
        goldGet,
        minLevel,
        type,
        startedAt,
        expiredAt
      );
      if (data) {
        notification["success"]({
          message: "Thành công",
          description: "GiftCode đã được tạo.",
        });
        getPaymentCodeList().then();
        form.resetFields();
        setOpenCreate(false);
        data.sort((a, b) => a.createdAt - b.createdAt).reverse();
        let text = data
          .map(function (item) {
            return item.id;
          })
          .join(", ");
        setCodeText(text);
        setOpenCreateTable(true);
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getPaymentCodeList().then();
  }, [filter, currentPage, pageSize]);

  const items = [
    {
      key: "1",
      label: "Thủ công",
      children: (
        <Form
          layout="vertical"
          form={form}
          className="flex w-full  form-search-filer flex-col "
          onFinish={onFinish}
        >
          <Form.Item label={<p className="font-bold ">Mã Code</p>} name="code">
            <Input
              placeholder="Mã code là mã gồm chữ in hoa và số"
              className="w-full flex-1"
            />
          </Form.Item>
          <Form.Item
            label={<p className="font-bold ">Số lượng nhận</p>}
            name="number"
            rules={[{required: true, message: "Chưa nhập số lượng nhận"}]}
          >
            <Input type="number" className="w-full flex-1"/>
          </Form.Item>
          <Form.Item
            label={<p className="font-bold">Xu nhận</p>}
            name="goldGet"
            rules={[{required: true, message: "Chưa nhập số lượng xu nhận"}]}
          >
            <Input type="number" className="w-full flex-1"/>
          </Form.Item>
          <Form.Item
            label={<p className="font-bold">Thời gian hết hạn</p>}
            name="expiredAt"
            rules={[{required: true, message: "Chưa chọn thời gian hết hạn"}]}
          >
            <DatePicker
              disabledDate={disabledDate}
              format={dateFormatPicker}
              showTime
              className="w-full flex-1"
            />
          </Form.Item>
          <Form.Item
            label={<p className="font-bold">Loại Code</p>}
            name="type"
            rules={[{required: true, message: "Loại code"}]}
          >
            <Input placeholder="Vd: 1" className="w-full flex-1"/>
          </Form.Item>
          <Form.Item>
            <div className="flex flex-1 justify-end">
              <Button
                htmlType="submit"
                className="text-white font-bold bg-gradient-to-r from-[#5a4edd] to-[#8a80fc] hover:bg-gradient-to-r hover:from-[#5a4edd] hover:to-[#8a80fc] focus:bg-gradient-to-r focus:from-[#5a4edd] focus:to-[#8a80fc] px-6 mt-4"
              >
                TẠO GIFTCODE
              </Button>
            </div>
          </Form.Item>
        </Form>
      ),
    },
    {
      key: "2",
      label: "Ngẫu nhiên",
      children: (
        <Form
          layout="vertical"
          form={form}
          className="flex w-full  form-search-filer flex-col "
          onFinish={onFinishCodeRandom}
        >
          <Form.Item
            label={<p className="font-bold">Khúc đầu Mã code</p>}
            name="prefix"
          >
            <Input
              placeholder="Gồm chữ in hoa hoặc số (không bắt buộc)"
              className="w-full flex-1"
            />
          </Form.Item>
          <Form.Item
            label={<p className="font-bold ">Khúc sau Mã code</p>}
            name="suffix"
          >
            <Input
              placeholder="Gồm chữ in hoa hoặc số (không bắt buộc)"
              className="w-full flex-1"
            />
          </Form.Item>
          <Form.Item
            label={<p className="font-bold ">Chiều dài mã code thêm giữa</p>}
            name="length"
          >
            <InputNumber min={1} className="w-full flex-1 flex-start"/>
          </Form.Item>
          <Form.Item
            label={<p className="font-bold ">Số lượng nhận</p>}
            name="number"
            rules={[{required: true, message: "Chưa nhập số lượng nhận"}]}
          >
            <Input type="number" className="w-full flex-1"/>
          </Form.Item>
          <Form.Item
            label={<p className="font-bold ">Xu nhận</p>}
            name="goldGet"
            rules={[{required: true, message: "Chưa nhập số lượng xu nhận"}]}
          >
            <Input type="number" className="w-full flex-1"/>
          </Form.Item>
          <Form.Item
            label={<p className="font-bold ">Thời gian hết hạn</p>}
            name="expiredAt"
            rules={[{required: true, message: "Chưa chọn thời gian hết hạn"}]}
          >
            <DatePicker
              disabledDate={disabledDate}
              format={dateFormatPicker}
              showTime
              className="w-full flex-1"
            />
          </Form.Item>
          <Form.Item
            label={<p className="font-bold ">Loại Code</p>}
            name="type"
            rules={[{required: true, message: "Chưa nhập loại code"}]}
          >
            <Input placeHolder="Vd: 1" className="w-full flex-1"/>
          </Form.Item>
          <Form.Item>
            <div className="flex flex-1 justify-end">
              <Button
                htmlType="submit"
                className="text-white font-bold bg-gradient-to-r from-[#5a4edd] to-[#8a80fc] hover:bg-gradient-to-r hover:from-[#5a4edd] hover:to-[#8a80fc] focus:bg-gradient-to-r focus:from-[#5a4edd] focus:to-[#8a80fc] px-6 mt-4"
              >
                TẠO GIFTCODE NL
              </Button>
            </div>
          </Form.Item>
        </Form>
      ),
    },
  ];

  function onChangePage(page, _pageSize) {
    if (_pageSize !== pageSize) {
      setPageSize(_pageSize)
      setCurrentPage(1);
    } else if (page !== currentPage) {
      setCurrentPage(page)
    }
  }

  return (
    <>
      <FormSearchFilter
        title="Danh sách Giftcode"
        initialValues={filter}
        onFormLayoutChange={(value) => {
          setFilter({...filter, ...value});
        }}
      >
        <Form.Item
          label="Code"
          className="form-item-filter"
          name="code"
        >
          <Input className="w-full"/>
        </Form.Item>
        <Form.Item label="Type Code" className="form-item-filter" name="type">
          <Input className="w-full"/>
        </Form.Item>
        <Form.Item label="Money" className="form-item-filter" name="money">
          <Input className="w-full"/>
        </Form.Item>
        <Form.Item label="Number" className="form-item-filter" name="number">
          <Input className="w-full"/>
        </Form.Item>
        <Button
          onClick={() => {
            setOpenCreate(true);
          }}
          className="text-white col-span-5 w-40 mt-5 justify-self-end	 col-end-9  font-bold bg-gradient-to-r from-[#5a4edd] to-[#8a80fc] hover:bg-gradient-to-r hover:from-[#5a4edd] hover:to-[#8a80fc] focus:bg-gradient-to-r focus:from-[#5a4edd] focus:to-[#8a80fc] px-6"
        >
          TẠO GIFTCODE
        </Button>
      </FormSearchFilter>
      <Table columns={columns} rowKey={'id'} dataSource={dataTable} pagination={{
        position: ['top'],
        total: 1000,
        current: currentPage,
        onChange: onChangePage,
        defaultCurrent: 1
      }}/>
      <Drawer
        closable={false}
        placement="right"
        onClose={() => {
          setOpenCreate(false);
        }}
        open={openCreate}
        width={550}
        bodyStyle={{padding: 0}}
      >
        <div className="py-3 px-5 flex justify-between items-center bg-[#F8F8F8]">
          Thông tin tạo mới
          <Button
            type="link"
            onClick={() => {
              setOpenCreate(false);
            }}
          >
            <FontAwesomeIcon
              icon={faXmark}
              style={{fontSize: "18px", color: "black"}}
            />
          </Button>
        </div>
        <div className="p-5">
          <Tabs defaultActiveKey="1" items={items}/>
        </div>
      </Drawer>

      <Modal
        title="DANH SÁCH CODE MỚI ĐƯỢC TẠO"
        centered
        open={openCreateTable}
        onOk={() => setOpenCreateTable(false)}
        onCancel={() => setOpenCreateTable(false)}
        width={920}
        footer={false}
      >
        <p style={{alignItems: "center"}}>{codeText}</p>
      </Modal>
    </>
  );
}
