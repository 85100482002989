import React, {useState, useEffect, useRef} from "react";
import {Form, Table, Input, DatePicker} from "antd";
import FormSearchFilter from "../../components/FormSearchFilter";
import APIService from "../../service/APIService";
import {priceFormat} from "../../utils";
import moment from "moment";
import getColumnSearchProps from "../../components/TableColumnSearchProps";

const {RangePicker} = DatePicker;
const dateFormatPicker = "DD-MM-YYYY";


export default function JackbotLogPage() {
  const [dataTable, setDataTable] = useState([]);
  const [filterDate, setFilterDate] = useState({
    timeFrom: "",
    timeTo: "",
  });

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [filterText, setFilterText] = useState('');
  const [filterColumn, setFilterColumn] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const searchInput = useRef(null);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 80,
      align: "center",
    },
    {
      title: "USER",
      dataIndex: "player",
      width: 180,
      key: "player",
      align: "center",
      ...getColumnSearchProps(dataTable.length, "player", setFilterText, setFilterColumn, setCurrentPage, searchInput, searchText, setSearchText, searchedColumn, setSearchedColumn)
    },
    {
      title: "TÊN HIỂN THỊ",
      dataIndex: "display_name",
      width: 180,
      key: "display_name",
      align: "center",
    },
    {
      title: "GAME",
      dataIndex: "game",
      key: "game",
      width: 160,
      align: "center",
    },
    {
      title: "LOẠI",
      dataIndex: "type",
      key: "type",
      width: 100,
      align: "center",
    },
    {
      title: "CURRENT MONEY",
      dataIndex: "current_money",
      key: "current_money",
      width: 160,
      render: (data) => priceFormat(data),
      align: "center",
    },
    {
      title: "CUREENT POOL",
      dataIndex: "current_pool",
      key: "current_pool",
      width: 160,
      align: "center",
    },
    {
      title: "UPDATE MONEY",
      dataIndex: "update_money",
      key: "update_money",
      width: 160,
      sorter: (a, b) => a.update_money - b.update_money,
      render: (data) => priceFormat(data),
      align: "center",

    },
    {
      title: "TIMESTAMP",
      dataIndex: "timestamp",
      key: "timestamp",
      align: "center",
      width: 160,
      render: (data, data1, index) => (
        <p>{moment(data * 1000).format('DD-MM-YYYY hh:mm:ss')}</p>
      ),
    },
  ];

  async function getJackpotLogList(filter = {}, page = 1, size = 10) {
    try {
      let wherePlayerId = "";
      let filterWhere = [];
      if (filter["player"]) {
        wherePlayerId = ` player like '%${filter["player"]}%'`;
        filterWhere.push(wherePlayerId);
      }
      const data = await APIService.jackpotLogList(filterDate.timeFrom, filterDate.timeTo, filterWhere.join(" AND "), page, size);
      if (data) {
        setDataTable(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  function onChangePage(page, _pageSize) {
    if (_pageSize !== pageSize) {
      setPageSize(_pageSize)
      setCurrentPage(1);
    } else if (page !== currentPage) {
      setCurrentPage(page)
    }
  }

  useEffect(() => {
    getJackpotLogList({
      [filterColumn]: filterText
    }, currentPage, pageSize).then();
  }, [filterDate, currentPage, pageSize, filterText]);

  return (
    <>
      <FormSearchFilter
        title="Fish Log Detail"
        initialValues={filterDate}
        onFormLayoutChange={(value) => {
          if (value.time) {
            setFilterDate({
              ...filterDate,
              timeFrom: moment(value.time[0]).format('YYYY-MM-DD'),
              timeTo: moment(value.time[1]).format('YYYY-MM-DD'),
            });
          } else {
            setFilterDate({timeFrom: "", timeTo: ""});
          }
        }}
      >
        <Form.Item label="Time" className="form-datepicker-filter" name="time">
          <RangePicker format={dateFormatPicker}/>
        </Form.Item>
      </FormSearchFilter>

      <Table columns={columns} rowKey={'id'} dataSource={dataTable} pagination={{
        position: ['top'], total: 1000, current: currentPage, onChange: onChangePage, defaultCurrent: 1
      }}/>
    </>
  );
}
