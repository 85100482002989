import React, {useState, useEffect, useRef} from "react";
import {Form, Table, Input, Select, Button, DatePicker, Space} from "antd";
import FormSearchFilter from "../../components/FormSearchFilter";
import moment from "moment";
import APIService from "../../service/APIService";
import _ from "lodash";
import { priceFormat } from "../../utils";
import {SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";

const { RangePicker } = DatePicker;
const dateFormatPicker = "DD-MM-YYYY";
const { Option } = Select;
export default function PaymentCardKhoPage() {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [filterText, setFilterText] = useState('');
  const [filterColumn, setFilterColumn] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleFilter = async (selectedKeys, confirm, dataIndex) => {
    if (selectedKeys[0]) {
      setFilterText(selectedKeys[0]);
      setFilterColumn(dataIndex);
      setCurrentPage(1);
    }
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
    setFilterText('');
    setCurrentPage(1);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters, close}) => (<div
      style={{
        padding: 8,
      }}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <Input
        ref={searchInput}
        placeholder={`nhập ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        style={{
          marginBottom: 8, display: 'block',
        }}
      />
      <Space>
        <Button
          size="small"
          onClick={() => handleFilter(selectedKeys, confirm, dataIndex)}
        >
          Lọc tất cả
        </Button>
        <Button
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          size="small"
          disabled={!!!dataTable.length}
        >
          Lọc trong trang
        </Button>
        <Button
          type={'link'}
          onClick={() => clearFilters && handleReset(clearFilters)}
          size="small"
          style={{
            width: 90,
          }}
        >
          Khôi phục
        </Button>
        <Button
          type="link"
          size="small"
          onClick={() => {
            close();
          }}
        >
          đóng
        </Button>
      </Space>
    </div>), filterIcon: (filtered) => (<SearchOutlined
      style={{
        color: filtered ? '#1677ff' : undefined,
      }}
    />), onFilter: (value, record) => {
      //console.log(value, record[dataIndex])
      return record[dataIndex] && record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
    }, onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    }, render: (text) => searchedColumn === dataIndex ? (<Highlighter
      highlightStyle={{
        backgroundColor: '#ffc069', padding: 0,
      }}
      searchWords={[searchText]}
      autoEscape
      textToHighlight={text ? text.toString() : ''}
    />) : (text),
  });

  const [dataTable, setDataTable] = useState([]);
  const [sum, setSum] = useState([]);
  const [filter, setFilter] = useState({
    timeFrom: "",
    timeTo: "",
  });
  const [form] = Form.useForm();
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 140,
      align: "center",
    },
    {
      title: "NHÀ MẠNG",
      dataIndex: "nhaMang",
      key: "nhaMang",
      width: 140,
      align: "center",
      ...getColumnSearchProps("nhaMang")
    },
    {
      title: "MỆNH GIÁ",
      dataIndex: "menhGia",
      key: "menhGia",
      width: 140,
      render: (data) => priceFormat(data),
      align: "center",
    },

    {
      title: "MÃ THẺ",
      dataIndex: "cardCode",
      key: "cardCode",
      width: 200,
    },
    {
      title: "SERI",
      dataIndex: "cardSeri",
      key: "cardSeri",
      width: 200,
    },
    {
      title: "TRẠNG THÁI",
      dataIndex: "status",
      key: "status",
      width: 200,
      align: "center",
      render: (data) => {
        switch (data) {
          case 1:
            return <p className="text-green-400	font-semibold">Còn</p>;

          default:
            return <p className="text-red-600	">Hết</p>;
        }
      },
    },

    {
      title: "MÃ GIAO DỊCH",
      dataIndex: "orderid",
      key: "orderid",
      width: 180,
      align: "center",
    },

    {
      title: "CẬP NHẬT LÚC",
      dataIndex: "updatedAt",
      key: "updatedAt",
      align: "center",
      width: 180,
      render: (data, data1, index) => (
        <p>{moment(data).format("DD-MM-YYYY")}</p>
      ),
    },
  ];

  const columnsSum = [
    {
      title: "SL Sử dụng",
      dataIndex: "slsudung",
      key: "slsudung",
      render: (data) => priceFormat(data),
      align: "center",
    },
    {
      title: "SL Còn",
      dataIndex: "slcon",
      key: "slcon",
      render: (data) => priceFormat(data),
      align: "center",
    },
    {
      title: "SL Tổng",
      dataIndex: "sltong",
      key: "sltong",
      render: (data) => priceFormat(data),
      align: "center",
    },

    {
      title: "Tổng sử dụng",
      dataIndex: "tongsudung",
      key: "tongsudung",
      render: (data) => priceFormat(data),
      align: "center",
    },
    {
      title: "Tổng còn",
      dataIndex: "tongcon",
      key: "tongcon",
      render: (data) => priceFormat(data),
      align: "center",
    },
    {
      title: "Tổng",
      dataIndex: "tong",
      key: "tong",
      render: (data) => priceFormat(data),
      align: "center",
    },
  ];

  async function getPaymentCardKhoList(filterCol = {}, page = 1, size = 10) {
    try {
      let wherePlayerId = "";
      let filterWhere = [];
      if (filterCol["nhaMang"]) {
        wherePlayerId = ` nhaMang like '%${filterCol["nhaMang"]}%'`;
        filterWhere.push(wherePlayerId);
      }
      const data = await APIService.paymentCardKhoList(
        filter.timeFrom,
        filter.timeTo,
        filterWhere.join(" AND "), page, size
      );
      if (data) {
        let objSum = {
          slsudung: 0,
          slcon: 0,
          sltong: 0,
          tongsudung: 0,
          tongcon: 0,
          tong: 0,
          id: 1
        };
        data.map((item) => {
          objSum.tong += parseFloat(item.menhGia);
          if (item.status == 1) {
            objSum.slcon += 1;
            objSum.tongcon += parseFloat(item.menhGia);
          } else {
            objSum.slsudung += 1;
            objSum.tongsudung += parseFloat(item.menhGia);
          }
          objSum.sltong += 1;
        });
        setDataTable(data);
        setSum([objSum]);
      }
    } catch (error) {
      console.error(error);
    }
  }

  function onChangePage(page, _pageSize) {
    if (_pageSize !== pageSize) {
      setPageSize(_pageSize)
      setCurrentPage(1);
    } else if (page !== currentPage) {
      setCurrentPage(page)
    }
  }

  useEffect(() => {
    getPaymentCardKhoList({
      [filterColumn]: filterText
    }, currentPage, pageSize).then();
  }, [currentPage, pageSize, filterText]);


  async function buyCard(values) {
    try {
      const data = await APIService.buyCard(
        values.nhamang,
        values.menhgia,
        values.soluong
      );
      if (data) getPaymentCardKhoList();
    } catch (error) {
      console.error(error);
    }
  }
  const onFinish = (values) => {
    buyCard(values);
  };

  return (
    <>
      <div className="p-6 border-y-[1px]">
        <p className="text-lg font-semibold">Nhập kho thẻ</p>
        <br></br>
        <Form
          layout="vertical"
          form={form}
          className="flex w-full  form-search-filer flex-col "
          onFinish={onFinish}
        >
          <Form.Item
            name="nhamang"
            label="Nhà mạng"
            rules={[{ required: true, message: "Chưa chọn nhà mạng" }]}
          >
            <Select placeholder="Nhà mạng">
              <Option value="VT">Viettel</Option>
              <Option value="Vina">Vinaphone</Option>
              <Option value="Mobi">Mobifone</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label={<p className="font-bold ">Mệnh giá</p>}
            name="menhgia"
            rules={[{ required: true, message: "Chưa nhập mệnh giá" }]}
          >
            <Input
              type="number"
              className="w-full flex-1"
              placeholder="Mệnh giá"
            />
          </Form.Item>
          <Form.Item
            label={<p className="font-bold">Số lượng</p>}
            name="soluong"
            rules={[{ required: true, message: "Chưa nhập số lượng" }]}
          >
            <Input
              type="number"
              className="w-full flex-1"
              placeholder="Số lượng"
            />
          </Form.Item>
          <Form.Item>
            <div className="flex flex-1 justify">
              <Button
                htmlType="submit"
                className="text-white font-bold bg-gradient-to-r from-[#5a4edd] to-[#8a80fc] hover:bg-gradient-to-r hover:from-[#5a4edd] hover:to-[#8a80fc] focus:bg-gradient-to-r focus:from-[#5a4edd] focus:to-[#8a80fc] px-6 mt-4"
              >
                Thêm vào
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
      <FormSearchFilter
        title="Kho thẻ"
        initialValues={filter}
        onFormLayoutChange={(value) => {
          if (value.time) {
            setFilter({
              ...filter,
              timeFrom: moment(value.time[0]).format("YYYY-MM-DD"),
              timeTo: moment(value.time[1]).format("YYYY-MM-DD"),
            });
          } else {
            setFilter({ ...filter, ...value });
          }
        }}
      >
        <Form.Item label="Time" className="form-datepicker-filter" name="time">
          <RangePicker format={dateFormatPicker} />
        </Form.Item>
      </FormSearchFilter>
      <Table columns={columns} rowKey={'id'} dataSource={dataTable} pagination={{
        position: ['top'],
        total: 1000,
        current: currentPage,
        onChange: onChangePage,
        defaultCurrent: 1
      }}/>
      <Table columns={columnsSum} rowKey={'id'}  dataSource={sum} />
    </>
  );
}
