import React, {useState, useEffect, useRef} from "react";
import { Form, Table, Input, DatePicker, Tooltip } from "antd";
import FormSearchFilter from "../../components/FormSearchFilter";
import APIService from "../../service/APIService";
import _ from "lodash";
import { strUcfirst } from "../../utils";
import moment from "moment";
import getColumnSearchProps from "../../components/TableColumnSearchProps";

const { RangePicker } = DatePicker;
const dateFormatPicker = "DD-MM-YYYY";


export default function TrackingLogPage() {
  const [dataTable, setDataTable] = useState([]);
  const [filterDate, setFilterDate] = useState({
    timeFrom: "",
    timeTo: "",
  });

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [filterText, setFilterText] = useState('');
  const [filterColumn, setFilterColumn] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const searchInput = useRef(null);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 140,
      align: "center",
    },
    {
      title: "USER",
      dataIndex: "player",
      key: "player",
      width: 180,
      ...getColumnSearchProps(dataTable.length, "player", setFilterText, setFilterColumn, setCurrentPage, searchInput, searchText, setSearchText, searchedColumn, setSearchedColumn)
    },
    {
      title: "REQUEST TYPE",
      dataIndex: "requestType",
      key: "requestType",
      align: "center",
      width: 140,
    },
    {
      title: "NỀN TẢNG",
      dataIndex: "platform",
      key: "platform",
      align: "center",
      width: 140,
    },
    {
      title: "HÀNH VI",
      dataIndex: "action",
      key: "action",
      width: 180,

    },
    {
      title: "IMEI",
      dataIndex: "imei",
      key: "imei",
      width: 200,
      align: "center",
    },
    {
      title: "IP",
      dataIndex: "IP",
      key: "IP",
      width: 180,

    },
   
    {
      title: "LÚC",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      width: 190,
      render: (data, data1, index) => (
        <p>{moment(data).format('DD-MM-YYYY hh:mm:ss')}</p>
      ),
    },
  ];

  async function getTrackingLogList(filter = {}, page = 1, size = 10) {
    try {
      let wherePlayerId = "";
      let filterWhere = [];
      if (filter["player"]) {
        wherePlayerId = ` player like '%${filter["player"]}%'`;
        filterWhere.push(wherePlayerId);
      }
      const data = await APIService.trackingLogList(filterDate.timeFrom, filterDate.timeTo, filterWhere.join(" AND "), page, size);
      if (data) {
        setDataTable(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  function onChangePage(page, _pageSize) {
    if (_pageSize !== pageSize) {
      setPageSize(_pageSize)
      setCurrentPage(1);
    } else if (page !== currentPage) {
      setCurrentPage(page)
    }
  }

  useEffect(() => {
    getTrackingLogList({
      [filterColumn]: filterText
    }, currentPage, pageSize).then();
  }, [filterDate, currentPage, pageSize, filterText]);

  return (
    <>
      <FormSearchFilter
        title="Fish Log Detail"
        initialValues={filterDate}
        onFormLayoutChange={(value) => {
          if (value.time) {
            setFilterDate({
              ...filterDate,
              timeFrom: moment(value.time[0]).format('YYYY-MM-DD'),
              timeTo: moment(value.time[1]).format('YYYY-MM-DD'),
            });
          } else {
            setFilterDate({timeFrom: "", timeTo: ""});
          }
        }}
      >
        <Form.Item label="Time" className="form-datepicker-filter" name="time">
          <RangePicker format={dateFormatPicker}/>
        </Form.Item>
      </FormSearchFilter>

      <Table columns={columns} rowKey={'id'} dataSource={dataTable} pagination={{
        position: ['top'], total: 1000, current: currentPage, onChange: onChangePage, defaultCurrent: 1
      }}/>
    </>
  );
}
