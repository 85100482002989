import React, { useState, useEffect, useRef } from "react";
import {Button, Input, Space, Table} from "antd";
import FormSearchFilter from "../../components/FormSearchFilter";
import moment from "moment";
import APIService from "../../service/APIService";
import _ from "lodash";
import { priceFormat, isCSKH } from "../../utils";
import { useSelector } from "react-redux";
import {SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";

export default function PaymentCodeDoiPage() {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [filterText, setFilterText] = useState('');
  const [filterColumn, setFilterColumn] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleFilter = async (selectedKeys, confirm, dataIndex) => {
    if (selectedKeys[0]) {
      setFilterText(selectedKeys[0]);
      setFilterColumn(dataIndex);
      setCurrentPage(1);
    }
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
    setFilterText('');
    setCurrentPage(1);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters, close}) => (<div
      style={{
        padding: 8,
      }}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <Input
        ref={searchInput}
        placeholder={`nhập ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        style={{
          marginBottom: 8, display: 'block',
        }}
      />
      <Space>
        <Button
          size="small"
          onClick={() => handleFilter(selectedKeys, confirm, dataIndex)}
        >
          Lọc tất cả
        </Button>
        <Button
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          size="small"
          disabled={!!!dataTable.length}
        >
          Lọc trong trang
        </Button>
        <Button
          type={'link'}
          onClick={() => clearFilters && handleReset(clearFilters)}
          size="small"
          style={{
            width: 90,
          }}
        >
          Khôi phục
        </Button>
        <Button
          type="link"
          size="small"
          onClick={() => {
            close();
          }}
        >
          đóng
        </Button>
      </Space>
    </div>), filterIcon: (filtered) => (<SearchOutlined
      style={{
        color: filtered ? '#1677ff' : undefined,
      }}
    />), onFilter: (value, record) => {
      //console.log(value, record[dataIndex])
      return record[dataIndex] && record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
    }, onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    }, render: (text) => searchedColumn === dataIndex ? (<Highlighter
      highlightStyle={{
        backgroundColor: '#ffc069', padding: 0,
      }}
      searchWords={[searchText]}
      autoEscape
      textToHighlight={text ? text.toString() : ''}
    />) : (text),
  });

  const [dataTable, setDataTable] = useState([]);
  const [filter] = useState({});
  const user = useSelector((state) => state.user.userState);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      width: 140,
      key: "id",
    },
    {
      title: "CODE",
      dataIndex: "code",
      width: 180,
      key: "code",
      ...getColumnSearchProps("code")
    },
    {
      title: "USER",
      dataIndex: "player",
      width: 140,
      key: "player",
      ...getColumnSearchProps("player")
    },
    {
      title: "TÊN HIỂN THỊ",
      dataIndex: "username",
      width: 200,
      key: "username",
      ...getColumnSearchProps("username")
    },
    {
      title: "NGƯỜI GỬI",
      dataIndex: "sender",
      width: 140,
      align: "center",
      key: "sender",
    },
    {
      title: "NUMBER",
      dataIndex: "number",
      width: 140,
      align: "center",
      key: "number",
    },
    {
      title: "TIỀN ĐỔI",
      dataIndex: "updateGold",
      width: 140,
      key: "updateGold",
      render: (data) => priceFormat(data),
    },
    {
      title: "VND",
      dataIndex: "vnd",
      width: 140,
      key: "vnd",
      render: (data) => priceFormat(data),
    },
    {
      title: "TIỀN USER",
      dataIndex: "gold",
      width: 140,
      key: "gold",
      render: (data) => priceFormat(data),
    },
    {
      title: "TRẠNG THÁI",
      dataIndex: "status",
      width: 140,
      align: "center",
      key: "status",
      render: (data) => {
        switch (data) {
          case 1:
            return <p className="text-green-400	font-semibold">Thành công</p>;

          default:
            return <p className="text-red-600	">Thất bại</p>;
        }
      },
    },
    {
      align: "center",
      title: "NỀN TẢNG",
      dataIndex: "platform",
      width: 140,
      key: "platform",
    },
    {
      title: "IP",
      dataIndex: "IP",
      width: 180,
      key: "IP",
    },
    {
      title: "CẬP NHẬT LÚC",
      dataIndex: "updatedAt",
      width: 140,
      key: "updatedAt",
      align: "center",
      render: (data, data1, index) => (
        <p>{moment(data).format("DD-MM-YYYY HH:mm:ss")}</p>
      ),
    },
  ];
  async function getPaymentCodeDoiList(filter = {}, page = 1, size = 10) {
    try {
      let wherePlayerId = "";
      let filterWhere = [];
      if (filter["player"]) {
        wherePlayerId = ` player like '%${filter["player"]}%'`;
        filterWhere.push(wherePlayerId);
      }

      if (filter["code"]) {
        wherePlayerId = ` code like '%${filter["code"]}%'`;
        filterWhere.push(wherePlayerId);
      }

      if (filter['username']) {
        const listUser = await APIService.searchUserInfo("", filter['username']);
        if (Array.isArray(listUser)) {
          const userids = listUser.map(i => i['userid']).join("','");

          filterWhere.push(` player IN('${userids}') `);
        } else {
          return setDataTable([]);
        }
      }

      const data = await APIService.paymentCodeDoiList(filterWhere.join(" AND "), page, size);
      if (data) {
        // cskh chỉ cho phép xem trong n ngày gần nhất
        if (isCSKH(user)) {
          let to = new Date();
          to.setHours(23);
          to.setMinutes(59);
          to.setSeconds(59);
          let from = new Date();
          from.setDate(
            to.getDate() - process.env.REACT_APP_LIMIT_CSKH_DATE_NUMBER
          );
          from.setHours(0);
          from.setMinutes(0);
          from.setSeconds(0);
          for (let i = 0; i < data.length; i++) {
            let createdDate = moment(data[i].timestamp);
            let limitDate = moment(from);
            if (createdDate.isBefore(limitDate)) {
              data.splice(i, 1);
              i--;
            }
          }
        }
        setDataTable(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  function onChangePage(page, _pageSize) {
    if (_pageSize !== pageSize) {
      setPageSize(_pageSize)
      setCurrentPage(1);
    } else if (page !== currentPage) {
      setCurrentPage(page)
    }
  }

  useEffect(() => {
    getPaymentCodeDoiList({
      [filterColumn]: filterText
    }, currentPage, pageSize).then();
  }, [currentPage, pageSize, filterText]);

  return (
    <>
      <FormSearchFilter
        title="Đổi Code"
        initialValues={filter}
      ></FormSearchFilter>
      <Table columns={columns} dataSource={dataTable} rowKey={'id'} pagination={{
        position: ['top'],
        total: 1000,
        current: currentPage,
        onChange: onChangePage,
        defaultCurrent: 1
      }}/>
    </>
  );
}
