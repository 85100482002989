import {Button, Input, Space} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import React from "react";

export default function getColumnSearchProps(rowLength, dataIndex, setFilterText, setFilterColumn, setCurrentPage, searchInput, searchText, setSearchText, searchedColumn, setSearchedColumn) {
  const handleFilter = async (selectedKeys, confirm, dataIndex) => {
    if (selectedKeys[0]) {
      setFilterText(selectedKeys[0]);
      setFilterColumn(dataIndex);
      setCurrentPage(1);
    }
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
    setFilterText('');
    setCurrentPage(1);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  return {
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters, close}) => (<div
      style={{
        padding: 8,
      }}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <Input
        ref={searchInput}
        placeholder={`nhập ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        style={{
          marginBottom: 8, display: 'block',
        }}
      />
      <Space>
        <Button
          size="small"
          onClick={() => handleFilter(selectedKeys, confirm, dataIndex)}
        >
          Lọc tất cả
        </Button>
        <Button
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          size="small"
          disabled={!!!rowLength}
        >
          Lọc trong trang
        </Button>
        <Button
          type={'link'}
          onClick={() => clearFilters && handleReset(clearFilters)}
          size="small"
          style={{
            width: 90,
          }}
        >
          Khôi phục
        </Button>
        <Button
          type="link"
          size="small"
          onClick={() => {
            close();
          }}
        >
          đóng
        </Button>
      </Space>
    </div>), filterIcon: (filtered) => (<SearchOutlined
      style={{
        color: filtered ? '#1677ff' : undefined,
      }}
    />), onFilter: (value, record) => {
      //console.log(value, record[dataIndex])
      return record[dataIndex] && record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
    }, onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    }, render: (text) => searchedColumn === dataIndex ? (<Highlighter
      highlightStyle={{
        backgroundColor: '#ffc069', padding: 0,
      }}
      searchWords={[searchText]}
      autoEscape
      textToHighlight={text ? text.toString() : ''}
    />) : (text),
  }
};
