/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useRef} from "react";
import {Table, Tooltip, Button, Input, Space, Modal} from "antd";
import FormSearchFilter from "../../components/FormSearchFilter";
import moment from "moment";
import APIService from "../../service/APIService";
import {priceFormat, isCSKH} from "../../utils";
import Highlighter from 'react-highlight-words';
import {useSelector} from "react-redux";
import {SearchOutlined} from '@ant-design/icons';
import UserDetailPage from "../user/user-detail-page";

export default function PaymentBankDuyetPage() {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [filterText, setFilterText] = useState('');
  const [filterColumn, setFilterColumn] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const searchInput = useRef(null);
  const [dataMoneyLog, setDataMoneyLog] = useState([]);
  const [open, setOpen] = useState(false);
  const [useridDetail, setUseridDetail] = useState(false);
  const [openUserDetail, setOpenUserDetail] = useState(false);

  const [currentPageMoneyLog, setCurrentPageMoneyLog] = useState(1);
  const [pageSizeMoneyLog, setPageSizeMoneyLog] = useState(10);
  const [moneyLogPlayer, setMoneyLogPlayer] = useState("");

  async function getMoneyLogList() {
    try {
      const data = await APIService.moneyLogList("", "", `player = '${moneyLogPlayer}'`, currentPageMoneyLog, pageSizeMoneyLog);
      if (data) {
        setDataMoneyLog(data);
        setOpen(true);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleFilter = async (selectedKeys, confirm, dataIndex) => {
    if (selectedKeys[0]) {
      setFilterText(selectedKeys[0]);
      setFilterColumn(dataIndex);
      setCurrentPage(1);
    }
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
    setFilterText('');
    setCurrentPage(1);
  };

  const [dataTable, setDataTable] = useState([]);
  const [filter] = useState({});
  const user = useSelector((state) => state.user.userState);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters, close}) => (<div
      style={{
        padding: 8,
      }}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <Input
        ref={searchInput}
        placeholder={`nhập ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        style={{
          marginBottom: 8, display: 'block',
        }}
      />
      <Space>
        <Button
          size="small"
          onClick={() => handleFilter(selectedKeys, confirm, dataIndex)}
        >
          Lọc tất cả
        </Button>
        <Button
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          size="small"
          disabled={!!!dataTable.length}
        >
          Lọc trong trang
        </Button>
        <Button
          type={'link'}
          onClick={() => clearFilters && handleReset(clearFilters)}
          size="small"
          style={{
            width: 90,
          }}
        >
          Khôi phục
        </Button>
        <Button
          type="link"
          size="small"
          onClick={() => {
            close();
          }}
        >
          đóng
        </Button>
      </Space>
    </div>), filterIcon: (filtered) => (<SearchOutlined
      style={{
        color: filtered ? '#1677ff' : undefined,
      }}
    />), onFilter: (value, record) => {
      //console.log(value, record[dataIndex])
      return record[dataIndex] && record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
    }, onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    }, render: (text) => searchedColumn === dataIndex ? (<Highlighter
      highlightStyle={{
        backgroundColor: '#ffc069', padding: 0,
      }}
      searchWords={[searchText]}
      autoEscape
      textToHighlight={text ? text.toString() : ''}
    />) : (text),
  });

  const columns = [
    {
      title: "ID", dataIndex: "id", width: 90, key: "id",
    },
    {
      title: "USER", dataIndex: "player", width: 130, key: "player", ...getColumnSearchProps("player"),
      render: (data, data1, index) => (
        <>
          <Button type={'link'} onClick={()=>{setUseridDetail(data); setOpenUserDetail(true)}}>{data}</Button>
          <Button
            className="text-center"
            type="text"
            danger
            onClick={() => {
              setCurrentPageMoneyLog(1);
              setMoneyLogPlayer(data);
            }}
          >
            LSC
          </Button>
        </>
      ),
    }, {
      title: "USERNAME", dataIndex: "username", width: 200, key: "username", ...getColumnSearchProps("username")
    }, {
      title: "MỆNH GIÁ", dataIndex: "menhGia", width: 120, key: "menhGia", render: (data) => priceFormat(data),
    }, {
      title: "TRẠNG THÁI", dataIndex: "status", width: 140, align: "center", key: "status", render: (data) => {
        switch (data) {
          case 0:
            return <p className="text-green-400	font-semibold">Chờ duyệt</p>;
          case 1:
            return <p className="text-green-400	font-semibold">Thành công</p>;
          case 2:
            return <p className="text-black-400	font-semibold">Không duyệt</p>;
          case 3:
            return <p className="text-black-400	font-semibold">Chờ cổng</p>;
          default:
            return <p className="text-green-400	"></p>;
        }
      },
    }, {
      title: "DUYỆT TAY", dataIndex: "duyetTay", width: 140, align: "center", key: "duyetTay", render: (data) => {
        switch (data) {
          case -1:
            return <p className="text-red-400	font-semibold">Lỗi</p>;
          case 0:
            return <p className="text-green-400	font-semibold">Bình thường</p>;
          case 1:
            return <p className="text-green-400	font-semibold">Thành công</p>;
          case 2:
            return <p className="text-black-400	font-semibold">Không duyệt</p>;
          case 3:
            return <p className="text-black-400	font-semibold">Chờ duyệt</p>;
          default:
            return <p className="text-green-400	"></p>;
        }
      },
    }, {
      title: "CẬP NHẬT LÚC",
      dataIndex: "updatedAt",
      width: 180,
      key: "updatedAt",
      align: "center",
      render: (data, data1, index) => (<p>{moment(data).format("DD-MM-YYYY HH:mm:ss")}</p>),
    }, {
      title: "DUYỆT", dataIndex: "", key: "", align: "center", width: 250, render: (data, data1, index) => {
        if (data1.duyetTay === 3 && data1.activeApprove === true) {
          return (<>
            <Button
              className="text-center"
              type="primary"
              onClick={() => {
                confirmBank(data1.orderid, 1);
              }}
            >
              Duyệt
            </Button>
            &nbsp;
            <Button
              className="text-center"
              type="primary"
              onClick={() => {
                confirmBank(data1.orderid, 0);
              }}
            >
              Không Duyệt
            </Button>
          </>);
        } else return "";
      },
    }, {
      title: "ĐỔI TRẠNG THÁI", dataIndex: "", key: "", align: "center", width: 550, render: (data, data1, index) => {
        if (data1.duyetTay === 3) {
          return (<>
            <Button
              className="text-center"
              type="primary"
              onClick={() => {
                changeBankStatus(data1.orderid, 1);
              }}
            >
              Thành Công
            </Button>
            &nbsp;
            <Button
              className="text-center"
              type="primary"
              onClick={() => {
                changeBankStatus(data1.orderid, 0);
              }}
            >
              Thất Bại
            </Button>
            &nbsp;
            <Button
              className="text-center"
              type="primary"
              onClick={() => {
                changeSunStatus(data1.orderid);
              }}
            >
              Rút từ Sun
            </Button>
            &nbsp;
            <Button
              className="text-center"
              type="primary"
              onClick={() => {
                changeHitStatus(data1.orderid);
              }}
            >
              Rút từ Hit
            </Button>
          </>);
        } else return "";
      },
    }, {
      title: "MÃ NGÂN HÀNG", dataIndex: "bankCode", width: 140, key: "bankCode",
    }, {
      title: "NGÂN HÀNG", dataIndex: "bankName", width: 150, key: "bankName",
    }, {
      title: "Short", dataIndex: "bankShort", width: 80, key: "bankShort",
    }, {
      title: "CHI TIẾT", dataIndex: "detail", key: "detail", width: 250, ellipsis: {
        showTitle: false,
      }, render: (data) => (<Tooltip placement="topLeft" title={data}>
        {data}
      </Tooltip>),
    }, {
      title: "MÃ GIAO DỊCH", dataIndex: "orderid", width: 180, key: "orderid", ...getColumnSearchProps("orderid")
    }, {
      title: "NGƯỜI GỬI", dataIndex: "sender", width: 140, align: "center", key: "sender",
    }];

  const columnsMoneyLog = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 140,
      sorter: (a, b) => a.id - b.id,
      align: "center",
    },

    {
      title: "GAME",
      width: 140,
      dataIndex: "game",
      key: "game",
    },
    {
      title: "LOG",
      width: 250,
      dataIndex: "log",
      key: "log",
      ellipsis: {
        showTitle: false,
      },
      render: (data) => (
        <Tooltip placement="topLeft" title={data}>
          {data}
        </Tooltip>
      ),
      align: "center",
    },
    {
      title: "TIỀN",
      width: 160,
      dataIndex: "gold",
      key: "gold",
      render: (data) => priceFormat(data),
    },
    {
      title: "TIỀN THẮNG/THUA",
      dataIndex: "updateGold",
      key: "updateGold",
      sorter: (a, b) => a.updateGold - b.updateGold,
      render: (data) => priceFormat(data),
      width: 200,
    },
    {
      title: "DETAIL",
      dataIndex: "detail",
      key: "detail",
      width: 250,
      ellipsis: {
        showTitle: false,
      },
      render: (data) => (
        <Tooltip placement="topLeft" title={data}>
          {data}
        </Tooltip>
      ),
    },
    {
      title: "TIME",
      dataIndex: "time",
      key: "time",
      width: 200,
      align: "center",
      sorter: (a, b) => moment(a.time).valueOf() - moment(b.time).valueOf(),
      render: (data, data1, index) => (
        <p>{moment(data).format("DD-MM-YYYY HH:mm:ss")}</p>
      ),
    },
  ];


  async function getPaymentBankDuyetList(filter = {}, page = 1, size = 10) {
    try {
      let wherePlayerId = "";
      let filterWhere = [];
      if (filter["player"]) {
        wherePlayerId = ` player like '%${filter["player"]}%'`;
        filterWhere.push(wherePlayerId);
      }
      if (filter["orderid"]) {
        wherePlayerId = ` orderid like '%${filter["orderid"]}%'`;
        filterWhere.push(wherePlayerId);
      }

      if (filter['username']) {
        const listUser = await APIService.searchUserInfo("", filter['username']);
        if (Array.isArray(listUser)) {
          const userids = listUser.map(i => i['userid']).join("','");

          filterWhere.push(` player IN('${userids}') `);
        } else {
          return setDataTable([]);
        }
      }

      const data = await APIService.paymentBankDuyetList(filterWhere.join(" AND "), page, size);
      if (data) {
        for (let i = 0; i < data.length; i++) data[i].activeApprove = true;
        // cskh chỉ cho phép xem trong n ngày gần nhất
        if (isCSKH(user)) {
          let to = new Date();
          to.setHours(23);
          to.setMinutes(59);
          to.setSeconds(59);
          let from = new Date();
          from.setDate(to.getDate() - process.env.REACT_APP_LIMIT_CSKH_DATE_NUMBER);
          from.setHours(0);
          from.setMinutes(0);
          from.setSeconds(0);
          for (let i = 0; i < data.length; i++) {
            // không cho phép duyệt mệnh giá từ 1tr
            if (data[i].menhGia > 3000000) data[i].activeApprove = false;
            let createdDate = moment(data[i].timestamp);
            let limitDate = moment(from);
            if (createdDate.isBefore(limitDate)) {
              data.splice(i, 1);
              i--;
            }
          }
        }
        setDataTable(data);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function confirmBank(orderid, type) {
    try {
      let text = "Bạn có muốn DUYỆT giao dịch bank này không ?";
      if (type === 0) text = "Bạn có muốn KHÔNG DUYỆT giao dịch bank này không ?";
      if (window.confirm(text) === true) {
        const data = await APIService.confirmBank(orderid, type);
        if (data) {
          getPaymentBankDuyetList({
            [filterColumn]: filterText
          }, currentPage, pageSize).then();
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function changeBankStatus(orderid, status) {
    try {
      let text = "Bạn có muốn chuyển trạng thái THÀNH CÔNG giao dịch bank này không ?";
      if (status === 0) text = "Bạn có muốn chuyển trạng thái THẤT BẠI giao dịch bank này không ?";
      if (window.confirm(text) === true) {
        const data = await APIService.changeBankStatus(orderid, status);
        if (data) {
          getPaymentBankDuyetList({
            [filterColumn]: filterText
          }, currentPage, pageSize).then();
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function changeSunStatus(orderid) {
    try {
      let text = "Bạn có muốn chuyển trạng thái RÚT TỪ SUN giao dịch bank này không ?";
      if (window.confirm(text) === true) {
        const data = await APIService.changeSunStatus(orderid);
        if (data) {
          getPaymentBankDuyetList({
            [filterColumn]: filterText
          }, currentPage, pageSize).then();
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function changeHitStatus(orderid) {
    try {
      let text = "Bạn có muốn chuyển trạng thái RÚT TỪ HIT giao dịch bank này không ?";
      if (window.confirm(text) === true) {
        const data = await APIService.changeHitStatus(orderid);
        if (data) {
          getPaymentBankDuyetList({
            [filterColumn]: filterText
          }, currentPage, pageSize).then()
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  function onChangePage(page, _pageSize) {
    if (_pageSize !== pageSize) {
      setPageSize(_pageSize)
      setCurrentPage(1);
    } else if (page !== currentPage) {
      setCurrentPage(page)
    }
  }

  function onChangePageMoneyLog(page, _pageSize) {
    if (_pageSize !== pageSizeMoneyLog) {
      setPageSizeMoneyLog(_pageSize)
      setCurrentPageMoneyLog(1);
    } else if (page !== currentPageMoneyLog) {
      setCurrentPageMoneyLog(page)
    }
  }

  useEffect(() => {
    getPaymentBankDuyetList({
      [filterColumn]: filterText
    }, currentPage, pageSize).then();
  }, [currentPage, pageSize, filterText]);

  useEffect(() => {
    if (moneyLogPlayer) {
      getMoneyLogList().then();
    }
  }, [moneyLogPlayer, currentPageMoneyLog, pageSizeMoneyLog]);

  return (<>
    <FormSearchFilter
      title="Duyệt Bank"
      initialValues={filter}
    ></FormSearchFilter>
    <Table columns={columns} rowKey={'id'} pagination={{
      position: ['top'],
      total: 1000,
      current: currentPage,
      onChange: onChangePage,
      defaultCurrent: 1
    }} dataSource={dataTable}/>

    <Modal
      title={dataMoneyLog[0]?.player}
      centered
      open={open}
      onOk={() => setOpen(false)}
      onCancel={() => setOpen(false)}
      width={1100}
      footer={false}
    >
      <Table columns={columnsMoneyLog} rowKey={'id'} dataSource={dataMoneyLog} pagination={{
        position: ['top'],
        total: 1000,
        current: currentPageMoneyLog,
        onChange: onChangePageMoneyLog,
        defaultCurrent: 1
      }}/>
    </Modal>

    <Modal
      title={"USER PROFILE"}
      centered
      open={openUserDetail}
      onOk={() => setOpenUserDetail(false)}
      onCancel={() => setOpenUserDetail(false)}
      width={1100}
      footer={false}
    >
      <UserDetailPage userid={useridDetail}/>
    </Modal>
  </>);
}
